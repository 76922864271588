import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue5({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="Freelancing, health, pensions and more"
            pageSocialImage=""
            pageTitle="Issue #5"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #5"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "It’s been such a busy month that I almost ended up skipping this months newsletter – but I’m glad I didn’t. It’s great to reflect on the month that’s passed and actually recognise things that have happened that were overshadowed by work.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at April"
                    content={[
                        {spans: [], text: "👷 Work", type: "heading4"},
                        {spans: [], text: "April has been my busiest month to date, but it was also my least profitable (in terms of billed work, much of this will overflow into next month).", type: "paragraph"},
                        {spans: [], text: "Why? My time was mostly taken up by a single project. I was approached by an agency around Nov/Dec last year to build a website for one of their clients.", type: "paragraph"},
                        {spans: [], text: "At the time there was nothing to go off of besides it being a fairly straight forward Jamstack marketing site (no complicated functionality) – based on that assumption we agreed it would be around 4 weeks of work.", type: "paragraph"},
                        {spans: [], text: "But as the date got closer and the designs started being circulated, it became apparent that this was a fixed price project – and thus my estimate of 4 weeks of work on a day rate had already been given to the end client (I should’ve pushed back on more of the work too – hindsight eh).", type: "paragraph"},
                        {spans: [], text: "I definitely learnt for cases like this – make it clear it’s an estimate and not a final quote until designs are provided.", type: "paragraph"},
                        {spans: [], text: "It’s not all doom and gloom though, I made a really good impression on the agency who hired me (they even invited me out for drinks) and I have no doubt we’ll work on projects together in the future. I also managed to get an extra ~£2k for some out of scope work.", type: "paragraph"},
                        {spans: [], text: "👨‍🎨 New project(s)", type: "heading4"},
                        {spans: [], text: "This month I managed to land a new client for ongoing design work. They’re a development team that build native and web apps that interact with physical products.", type: "paragraph"},
                        {spans: [], text: "So far I’ve started working on prototypes for 2 mobile apps. I definitely prefer working on products when it comes to design – as it involves more problem-solving skills compared to marketing sites.", type: "paragraph"},
                        {spans: [], text: "I now have 2 clients for ongoing design work which I think will keep me satisfied from a design perspective for some time.", type: "paragraph"},
                        {spans: [], text: "📬 Inquiries", type: "heading4"},
                        {spans: [], text: "This month was crazy when it came to new work inquiries. I was so busy I struggled to find time to even reply to them. Oddly, the majority of them didn’t really pique my interest (rewind to when I started freelancing and I probably would’ve jumped at almost all of them).", type: "paragraph"},
                        {spans: [], text: "Again the biggest problem I’m facing with projects that do sound interesting is that they’re looking to book someone in ASAP – which I just don’t see happening any time soon from my work schedule.", type: "paragraph"},
                        {spans: [], text: "💻 Website progress", type: "heading4"},
                        {spans: [], text: "One of my goals for this month was to launch my new website – that did not happen ☹️. But I did manage to finish building all the components for my core pages (despite my busy schedule).", type: "paragraph"},
                        {spans: [], text: "I now need to find time to design some images for certain sections of my site so that I can launch an MVP – hopefully, May can be that month.", type: "paragraph"},
                        {spans: [], text: "🚴‍♂️ Cycling", type: "heading4"},
                        {spans: [], text: "At the start of the month, I bought myself a bike. I’ve always enjoyed cycling and wanted to get back into it but the number of reasons started pilling up; an enjoyable form of exercise, something to do with my daughter and a way to reduce my carbon footprint even more.", type: "paragraph"},
                        {spans: [], text: "It’s great to go for a quick ride before work or at lunch and I also pick my daughter up from nursery if the weather is nice.", type: "paragraph"},
                        {spans: [], text: "This month I‘ve spent ~8 hours cycling.", type: "paragraph"},
                        {spans: [], text: "🛌 Sleep", type: "heading4"},
                        {spans: [], text: "This month, I’ve really gotten back into books around health. To me, it’s important that I don’t sacrifice my long term health (which I’ve not been doing a great job of this month).", type: "paragraph"},
                        {spans: [], text: "A book that I started reading this month is “Why we sleep” – which suggests sleep is more important for our long term health than exercise and diet (and we know how important they are).", type: "paragraph"},
                        {spans: [], text: "My main takeaway so far (that applies to me); if you set an alarm and you feel like you could carry on sleeping when it goes off, you’ve likely not had enough sleep.", type: "paragraph"},
                        {spans: [], text: "Previously I had an alarm set for 6 am and most mornings I’d still be tired when this alarm went off (especially if I worked out the night before).", type: "paragraph"},
                        {spans: [], text: "As a result, I’ve turned my alarm off and have found I need another 45m-90m more sleep depending on if I’ve worked out or not.", type: "paragraph"},
                        {spans: [], text: "⌚️ Fitbit", type: "heading4"},
                        {spans: [], text: "I finally bit the bullet and pre-ordered myself a Fitbit – the Fitbit Luxe (I won’t get it until the end of May though).", type: "paragraph"},
                        {spans: [], text: "The number one reason I wanted the Fitbit was to actually measure my stress levels (we’re probably all a bit more stressed from the year we’ve just had).", type: "paragraph"},
                        {spans: [], text: "Whilst I don’t feel stressed I’m getting a couple of indications from my body that might be stress-related so I want to see if that is the case and to also keep it under control.", type: "paragraph"},
                        {spans: [], text: "Secondly, I want to track my sleep, weight workouts, cycling and swimming (which I can finally start).", type: "paragraph"},
                        {spans: [], text: "It’s easy to forget about your health when things get busy, but especially as a freelancer you need to look after your health – you don’t get paid sick leave and you still need to manage all of your clients if you’re taken out of action.", type: "paragraph"},
                        {spans: [], text: "💰 Pension", type: "heading4"},
                        {spans: [], text: "I had a bit of a wobble with my pension this month. At the start of the year, I opened an account with Penfold and started putting away £400 each month.", type: "paragraph"},
                        {spans: [], text: "But when I paid my money in at the end of March nothing appeared for close to 2 weeks, I then had to message them 3 times before it finally got sorted.", type: "paragraph"},
                        {spans: [], text: "I’ve been assured this was a one-off and won’t happen again, but the whole experience has made me feel a little bit uneasy. I don’t have any plans to move away from them, but if something like this was to happen again then I’d likely consider different options.", type: "paragraph"},
                        {spans: [], text: "🐦 Twitter", type: "heading4"},
                        {spans: [], text: "I started being more active on Twitter this month. I think going forward this will be the platform where I share most of my updates – I’ve even started getting DMs from people looking for advice on freelancing.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 April goals"
                    checkList={[
                        {complete: false, text: "🚀 Launch my new business website"},
                        {complete: false, text: "🚀 Launch my new personal website"},
                        {complete: false, text: "🌟 Land a decent sized project for May"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to May"
                    content={[
                        {spans: [], text: "I’m kicking May off in the best possible way – with a holiday. This is a much-needed break after such a busy start to the year. A lot of things still aren’t open in the UK and the weather forecast isn’t looking great but it will still be great to getaway.", type: "paragraph"},
                        {spans: [], text: "Once I get back from holiday, I have a bunch of work lined up for all sorts of projects – it’s mostly carrying on with existing projects and will probably be more of a balance between design and development work.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 May goals"
                    checkList={[
                        {complete: false, text: "👨‍🍳 Meal prep every Sunday"},
                        {complete: false, text: "🏊‍♂️ Swim once every other week"},
                        {complete: false, text: "🏋️‍♂️ Train 4 times per week"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="Focus on the things you can control. This really helped me when I took the plunge into freelancing. There were so many things to consider and do, but I narrowed my focus to what was important and focused on the things within my control."
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/6546a236-adec-4251-a32c-9a8baac31320_apr-2021-income.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £2.4k", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/d56b804f-c426-40f4-b4a4-f9f694d7b439_bike.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Carrera Crossfire Hybrid Bike", type: "heading4"},
                        {spans: [], text: "I picked up this bike from the Facebook market place. I was initially looking to buy a new one from Halfords – but opted for a second hand one and it’s been a great decision. I decided to go for a hybrid bike because I wanted to cycle on-road and off-road, so the hybrid sounded like the best all-rounder for this. Also, just a reminder that you don’t need the best, newest stuff to get started with something.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/531fc136-f8f1-4ca1-b705-5620f84db70a_book-cant-hurt-me.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Can’t Hurt Me by David Goggins", type: "heading4"},
                        {spans: [], text: "Every now and again I’ll pick up a book that shares someone’s life story. It’s amazing to hear the stories David tells on how he pushed the limit of what is considered possible. Just one example, where he finished his first ultra-marathon with failed kidneys, broken bones in his feet and fractures in his legs. It goes to show the real power of our minds and that we’re all capable of a lot more than we think possible.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.audible.co.uk/pd/Cant-Hurt-Me-Audiobook/B07KKNG85B",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue5;
